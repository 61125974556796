// extracted by mini-css-extract-plugin
export var blog = "PlasmicHomepage-module--blog--bffe1";
export var books = "PlasmicHomepage-module--books--6f802";
export var copyright = "PlasmicHomepage-module--copyright--54783";
export var freeBox___9VZu = "PlasmicHomepage-module--freeBox___9VZu--17461";
export var freeBox__kP5V = "PlasmicHomepage-module--freeBox__kP5V--6a9c7";
export var freeBox__rJ1B8 = "PlasmicHomepage-module--freeBox__rJ1B8--3dbe3";
export var h1 = "PlasmicHomepage-module--h1--897da";
export var music = "PlasmicHomepage-module--music--1baf2";
export var resume = "PlasmicHomepage-module--resume--fdcff";
export var root = "PlasmicHomepage-module--root--63d19";
export var talks = "PlasmicHomepage-module--talks--cfc9a";