// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react"
import { PlasmicHomepageLink } from "./plasmic/isaacpalka_com/PlasmicHomepageLink"
import { Helmet } from "react-helmet"

function HomepageLink_(props, ref) {
  // Use PlasmicHomepageLink to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicHomepageLink are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all HomepageLinkProps here, but feel free
  // to do whatever works for you.
  return (
    <>
      <Helmet>
        <title>Isaac Palka</title>
        <meta name="description" content="Isaac Palka's Personal Homepage" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        ></meta>
      </Helmet>
      <PlasmicHomepageLink root={{ ref }} {...props} />
    </>
  )
}

const HomepageLink = React.forwardRef(HomepageLink_)

export default HomepageLink
